import { FC } from 'react';

import { TProductCategory } from '@lib/core/products/types';
import { ILocaleText } from '@lib/tools/locale/views/LocaleFragment';
import {
  PRODUCT_CATEGORY_BEER,
  PRODUCT_CATEGORY_COFFEE,
  PRODUCT_CATEGORY_WINE,
} from '@lib/tools/shared/helpers/consts';

import defaultFidelityCardIcon from '@components/web/src/assets/icons/default_fidelity_card_icon.png';
import arrowIcon from '@components/web/src/assets/legacy/b2b/kiosk/icons/union_qr_arrow_icon.svg';
import beerImage from '@components/web/src/assets/legacy/b2b/kiosk/sketches_barcodescan_beer.png';
import coffeeImage from '@components/web/src/assets/legacy/b2b/kiosk/sketches_barcodescan_coffee.png';
import wineImage from '@components/web/src/assets/legacy/b2b/kiosk/sketches_barcodescan_wine.png';
import * as S from '@components/web/src/atoms/ScanCTA/styles';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Text } from '@components/web/src/foundations/Text/Text';

export type TOrientation = 'left' | 'right';

interface IProps {
  orientation: TOrientation;
  productCategory: TProductCategory;
  icon?: 'product' | 'fidelityCard';
  title: ILocaleText;
  description: ILocaleText;
  retailerFidelityCardImage?: string;
  arrow?: boolean;
  qrCodeUrl?: string;
}

const ScanCTA: FC<IProps> = ({
  orientation = 'left',
  productCategory,
  title,
  description,
  arrow = false,
  retailerFidelityCardImage,
  qrCodeUrl,
  icon,
}) => {
  const getIcon = () => {
    const productCategoryImages = {
      [PRODUCT_CATEGORY_BEER]: beerImage,
      [PRODUCT_CATEGORY_COFFEE]: coffeeImage,
      [PRODUCT_CATEGORY_WINE]: wineImage,
    };

    switch (icon) {
      case 'fidelityCard':
        return <S.Icon alt="fidelityCard icon" src={retailerFidelityCardImage || defaultFidelityCardIcon} />;
      case 'product':
        return <S.Icon alt="product icon" src={productCategoryImages[productCategory]} />;
      default:
        return '';
    }
  };

  return (
    <S.ScanCtaContainer $orientation={orientation} data-testid={`ScanCTA-${orientation}`}>
      <S.ImageWrapper $orientation={orientation}>
        <S.CircleIcon backgroundColor="var(--color-secondary-100)" />
        {(qrCodeUrl && <S.QrIcon size={143} value={qrCodeUrl} />) || getIcon()}
        {arrow && <S.ArrowIcon alt="arrow" src={arrowIcon} />}
      </S.ImageWrapper>
      <S.TextWrapper $orientation={orientation}>
        <Text color={STATIC_COLORS.teal['600']} size="h6" text={title} weight="semibold" />
        <Text color={STATIC_COLORS.base.black} size="subtitle1" text={description} weight="medium" />
      </S.TextWrapper>
    </S.ScanCtaContainer>
  );
};

export default ScanCTA;

import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useCharacters } from '@lib/core/characters/hooks';
import { parseCharacterDetails } from '@lib/core/characters/utils';
import { isCharacterByIdentifiers, isCharacterByServiceProductCategory } from '@lib/core/characters/utils/filters';
import { useRetailer } from '@lib/core/retailers/hooks/retailer';
import { useRetailerLocation } from '@lib/core/retailers/hooks/retailerLocation';
import { GPRL_CHARACTER_QUERY, IS_FROM_PRODUCT_DETAILS_PAGE } from '@lib/core/service/consts';
import { useApp } from '@lib/core/service/hooks';
import { useKioskRoot } from '@lib/core/service/hooks/useKioskRoot';
import { fetchResultPageProducts, resetResultPageProducts } from '@lib/core/service/slices/kioskRootSlice';
import { prependBasename } from '@lib/core/service/utils';
import { useProductList, useUser } from '@lib/core/users/hooks';
import { useFidelityCard } from '@lib/core/users/hooks/useFidelityCard';
import MixpanelTracker from '@lib/tools/dat/mixpanel';
import { removeUrlParams } from '@lib/tools/shared/helpers';
import { PRODUCT_CATEGORY_QUERY } from '@lib/tools/shared/helpers/consts';
import { useAddons } from '@lib/tools/views/hooks';
import { PAGES } from '@lib/tools/views/urls';

import ResultPageSkeleton from '@components/web/src/organisms/Skeleton/ResultPageSkeleton/ResultPageSkeleton';
import ResultPage from '@components/web/src/pages/kiosk/ResultPage/ResultPage';

const ResultContainer: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { locale, productCategory } = useApp();

  const { isFetchingUserData, userCharacters, userCharactersIds } = useUser();
  const { isFidelityCardLoading, isFidelityCardScanned } = useFidelityCard();
  const { characters, isCharactersLoading } = useCharacters();

  const currentUserCharacterData = characters
    .filter(character => isCharacterByIdentifiers(character, userCharactersIds))
    .find(character => isCharacterByServiceProductCategory(character));
  const { retailerStoreType } = useRetailerLocation();
  const { isEnableKioskFidelityScanAddon, isEnableLocationMapAddon, isShowProductLocationAddon, isEnableVusionAddon } =
    useAddons();
  const { retailerFidelityCardImage } = useRetailer();

  const { products, isProductsLoading } = useKioskRoot();
  const isFromProductDetailsPage = !!searchParams.get(IS_FROM_PRODUCT_DETAILS_PAGE);

  const [showContent, setShowContent] = useState(isFromProductDetailsPage);
  const parsedCharacter = parseCharacterDetails({ character: currentUserCharacterData || {} });

  const { wishlistProductInstanceIds, isWishlistProductListLoading, handleUpdateWishlistProductList } =
    useProductList();

  const navigateToProductCatalogPage = () => {
    navigate(prependBasename(PAGES.vinhood.catalog) + (searchParams.toString() ? `?${searchParams.toString()}` : ''));
  };

  useEffect(() => {
    if (!isFromProductDetailsPage) {
      dispatch(
        fetchResultPageProducts({
          [GPRL_CHARACTER_QUERY]: currentUserCharacterData?.identifier,
          [PRODUCT_CATEGORY_QUERY]: productCategory,
          offset: 0,
        }),
      );
    } else {
      removeUrlParams({
        keys: [IS_FROM_PRODUCT_DETAILS_PAGE],
      });
    }

    return () => {
      if (!window.location.pathname.includes(PAGES.vinhood.product)) {
        dispatch(resetResultPageProducts());
      }
    };
  }, [userCharacters]);

  useEffect(() => {
    const isDataLoading = isFidelityCardLoading || isProductsLoading || isFetchingUserData || isCharactersLoading;

    setTimeout(() => {
      setShowContent(!isDataLoading);
    }, 2000);
  }, [isFidelityCardLoading, isFetchingUserData, isProductsLoading, isCharactersLoading]);

  useEffect(() => {
    if (currentUserCharacterData) {
      MixpanelTracker.events.resultPageView([currentUserCharacterData]);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  }, [currentUserCharacterData]);

  return (
    <>
      {showContent ? (
        <ResultPage
          shouldHideComment
          shouldHideFeedback
          characterData={currentUserCharacterData}
          handleUpdateWishlistProductList={handleUpdateWishlistProductList}
          isEnableKioskFidelityScanAddon={isEnableKioskFidelityScanAddon}
          isEnableLocationMapAddon={isEnableLocationMapAddon}
          isEnableVusionAddon={isEnableVusionAddon}
          isFidelityCardScanned={isFidelityCardScanned}
          isProductsLoading={isProductsLoading}
          isShowProductLocationAddon={isShowProductLocationAddon}
          isWishlistProductListLoading={isWishlistProductListLoading}
          locale={locale}
          navigateToProductCatalogPage={navigateToProductCatalogPage}
          parsedCharacter={parsedCharacter}
          productCategory={productCategory}
          products={products[0]}
          retailerFidelityCardImage={retailerFidelityCardImage}
          shouldHideWishlist={!isFidelityCardScanned}
          storeType={retailerStoreType}
          wishlistProductInstanceIds={wishlistProductInstanceIds}
        />
      ) : (
        <ResultPageSkeleton isFromSituationTest={false} />
      )}
    </>
  );
};

export default ResultContainer;

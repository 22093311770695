import { FC } from 'react';

import { TCharacterEssences } from '@lib/core/characters/types';
import { TProductCategory } from '@lib/core/products/types';
import { localeKiosk } from '@lib/tools/locale/source/web/kiosk';

import { STATIC_COLORS } from '@components/web/src/foundations';
import { Text } from '@components/web/src/foundations/Text/Text';
import ResultEssenceItem from '@components/web/src/organisms/KioskCharacterDetails/ResultEssenceItem/ResultEssenceItem';
import * as S from '@components/web/src/organisms/KioskCharacterDetails/styles';

export type Props = {
  productCategory: TProductCategory;
  characterImage: string;
  characterName: string;
  characterCaption: string;
  sortedRelatedCharacterEssences: TCharacterEssences[];
  characterDescription: string;
};
export const KioskCharacterDetails: FC<Props> = ({
  productCategory,
  characterImage,
  characterName,
  characterCaption,
  sortedRelatedCharacterEssences,
  characterDescription,
}) => {
  const { characterTitle } = localeKiosk.characterDetails;
  return (
    <S.CharacterDetailsContainer
      align="flex-start"
      data-testid={`KioskCharacterDetails-${productCategory}`}
      justify="center"
    >
      <S.CharacterImage $characterImage={characterImage} />
      <S.CharacterSummary direction="column">
        <S.CharacterTitle color={STATIC_COLORS.base.black} size="body1" text={characterTitle} />
        <S.CharacterName
          color={STATIC_COLORS.productColors[productCategory][500]}
          fontFamily="Fraunces"
          size="h4"
          text={`#${characterName}`}
          weight="semibold"
        />
        <Text color={STATIC_COLORS.base.black} size="subtitle1" text={characterCaption} weight="medium" />

        <S.CharacterEssenceGroup align="flex-start" direction="column" gap={8}>
          {sortedRelatedCharacterEssences.map(essence => (
            <ResultEssenceItem key={essence.identifier} {...essence} variant={productCategory} />
          ))}
        </S.CharacterEssenceGroup>
        <Text color={STATIC_COLORS.base.black} size="body2" text={characterDescription} />
      </S.CharacterSummary>
    </S.CharacterDetailsContainer>
  );
};

export default KioskCharacterDetails;

import { Navigate, Outlet } from 'react-router-dom';

import { prependBasename } from '@lib/core/service/utils';
import { useUser } from '@lib/core/users/hooks';
import { PAGES } from '@lib/tools/views/urls';

export const KioskRoutes = () => {
  const { isUserKioskAdmin } = useUser();
  return isUserKioskAdmin ? <Outlet /> : <Navigate replace to={PAGES.vinhood.kioskLogin} />;
};

export const AnonRoutes = () => {
  const { isUserKioskAdmin } = useUser();

  return isUserKioskAdmin ? <Navigate replace to={prependBasename(PAGES.vinhood.welcome)} /> : <Outlet />;
};

export const KioskCatchAll = () => {
  const { isUserKioskAdmin } = useUser();

  return <Navigate replace to={isUserKioskAdmin ? prependBasename(PAGES.vinhood.welcome) : PAGES.vinhood.kioskLogin} />;
};
